import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { TextField } from "..";
import { IInventarioProductoUnidadMedida } from "../../../../AppInterfaces";
import { Consultar } from "../../server/funcionesServidor";
import { getAbbreviatura } from "../../contantes/unidadesMedida";

export function AutocompleteInventarioProductoUnidadMedida({
  unidadMedidaId,
  productoId,
  onChange,
  label = "Unidad de Medida",
  required = false,
  disabled = false,
  error,
  isModificando = true,
  unidadMedidaPrincipalNombre = "",
}: {
  unidadMedidaId?: number;
  productoId: number;
  onChange: (documento: IInventarioProductoUnidadMedida) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  isModificando?: boolean;
  unidadMedidaPrincipalNombre?: string;
}) {
  const [unidadesMedida, setUnidadesMedida] =
    useState<IInventarioProductoUnidadMedida[]>();

  const consultar = useCallback(async () => {
    let unidadesMedida = await Consultar<IInventarioProductoUnidadMedida>(
      `api/InventarioProductoUnidadesMedida/consultar`,
      undefined,
      undefined,
      {
        where: `productoId = ${productoId}`,
        order: "nombre asc",
      }
    );

    if (unidadesMedida) {
      unidadesMedida.unshift({
        id: 0,
        productoId: productoId,
        nombre: unidadMedidaPrincipalNombre,
        equivalencia: 1,
      });
    }

    setUnidadesMedida(unidadesMedida);
  }, [productoId, unidadMedidaPrincipalNombre]);

  useEffect(() => {
    consultar();
  }, [consultar]);

  const unidadMedidaSeleccionada = unidadesMedida?.find(
    (unidad) => unidad.id === unidadMedidaId
  );

  return isModificando ? (
    <Autocomplete
      options={
        unidadesMedida?.map((e) => ({
          id: e.id ?? 0,
          productoId: e.productoId ?? 0,
          nombre: e.nombre ?? "",
          equivalencia: e.equivalencia ?? 0,
        })) ?? [
          {
            id: 0,
            productoId: 0,
            nombre: unidadMedidaPrincipalNombre,
            equivalencia: 0,
          },
        ]
      }
      getOptionLabel={(option) => getAbbreviatura(option.nombre)}
      isOptionEqualToValue={(option, value) =>
        JSON.stringify(option) === JSON.stringify(value)
      }
      renderInput={(params) => (
        <TextField
          params={{ ...params }}
          required={required}
          name="ACUnidadMedida"
          label={label}
          error={error}
        />
      )}
      onChange={(event, value) => {
        if (value) {
          onChange({
            id: value.id,
            productoId: value.productoId,
            nombre: value.nombre,
            equivalencia: value.equivalencia,
          });
        }
      }}
      value={{
        id: unidadMedidaSeleccionada?.id ?? 0,
        productoId: unidadMedidaSeleccionada?.productoId ?? 0,
        nombre: unidadMedidaSeleccionada?.nombre ?? unidadMedidaPrincipalNombre,
        equivalencia: unidadMedidaSeleccionada?.equivalencia ?? 0,
      }}
      disabled={disabled}
      disableClearable
    />
  ) : (
    <div>
      <div>
        <strong>{label}</strong>
      </div>
      <div>
        {getAbbreviatura(
          unidadMedidaSeleccionada?.nombre || unidadMedidaPrincipalNombre
        )}
      </div>
    </div>
  );
}
