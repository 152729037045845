import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { IAuthContext } from "../../../AppInterfaces";
import { authContext } from "../../MainComponent";
import classes from "./cabezeraInforme.module.css";

export default function CabezeraInforme({
  titulo,
  textoFiltros,
  noUsarNombreCompania = false,
  usarLogo,
}: {
  titulo?: string;
  textoFiltros?: string | React.ReactNode;
  noUsarNombreCompania?: boolean;
  usarLogo?: boolean;
}) {
  const {
    companiaSeleccionada: {
      nombre: companiaNombre,
      logo: companiaLogo,
      CxCConfiguracion: { isImprimirInfoUsuarioFecha },
    },
    username,
  } = useContext(authContext) as IAuthContext;

  return (
    <div className="impresionCabezera">
      {isImprimirInfoUsuarioFecha && (
        <>
          <div className={classes.fecha}>{new Date().toLocaleString()}</div>
          <div className={classes.usuario}>{username}</div>
        </>
      )}

      <div>
        <Grid container>
          {usarLogo && companiaLogo && (
            <Grid item>
              <img
                src={"data:image/png;base64," + companiaLogo}
                alt="Logo Compania"
                width={200}
                height={80}
              />
            </Grid>
          )}

          <Grid item style={{ flex: 1 }}>
            {companiaNombre && !noUsarNombreCompania && (
              <div className={classes.compania}>{companiaNombre}</div>
            )}

            {titulo && <div className={classes.titulo}>{titulo}</div>}

            {textoFiltros && (
              <div className={classes.filtros}>{textoFiltros}</div>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
