import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import BusquedaGrid from "./busquedaGrid";
import { Button, TextField } from "@mui/material";
import { Column } from "@material-table/core";
import { FormatearFecha } from "../funciones/funciones";

export default function Busqueda({
  funcionCerrar,
  collection,
  where,
  order,
  otrosParametros,
  listadoManual,
  permitirNuevoRegistro,
  columnas,
  usarFiltros,
}: {
  funcionCerrar: (registro?: any, nuevoRegistro?: boolean) => void;
  collection: string;
  where?: string;
  order?: string;
  otrosParametros?: object;
  listadoManual?: any[];
  permitirNuevoRegistro?: string;
  columnas?: Column<any>[];
  usarFiltros?: boolean;
}) {
  const [filtrosLocal, setFiltrosLocal] = React.useState(
    usarFiltros
      ? {
          fechaDesde: FormatearFecha(new Date(), "YYYY-MM-DD"),
          fechaHasta: FormatearFecha(new Date(), "YYYY-MM-DD"),
        }
      : {}
  );

  return (
    <Modal isOpen={true} zIndex={2000} size="xl">
      {usarFiltros && (
        <ModalHeader>
          <TextField
            name="fecha"
            label="Fecha de consulta"
            onChange={(e) =>
              setFiltrosLocal({
                ...filtrosLocal,
                fechaDesde: e.target.value,
                fechaHasta: e.target.value,
              })
            }
            value={filtrosLocal.fechaDesde}
            type="date"
          />
        </ModalHeader>
      )}

      <ModalBody className="modalBody" style={{ padding: "0px" }}>
        <BusquedaGrid
          onClick={funcionCerrar}
          collection={collection}
          where={where}
          order={order}
          otrosParametros={{ ...otrosParametros, ...filtrosLocal }}
          listadoManual={listadoManual}
          columnas={columnas}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          onClick={() => funcionCerrar()}
          variant="contained"
          color="secondary"
        >
          Cancelar
        </Button>

        {permitirNuevoRegistro && (
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => funcionCerrar(null, true)}
            variant="contained"
            color="primary"
          >
            Nuevo
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
