import { Box, Button, Stack, TextField } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";

export default function NuevaContrasena() {
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");

  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");

  const handleCambiarContrasena = () => {
    if (newPassword !== newPasswordConfirm) {
      alert("Las contraseñas no coinciden");
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_URL_PROD}api/Usuarios/reset-password?access_token=${accessToken}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          newPassword,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          alert("Contraseña cambiada correctamente");
          window.location.href = "/login";
        } else {
          alert("Error al cambiar la contraseña");
        }
      })
      .catch((err) => {
        alert("Error al cambiar la contraseña");
      });
  };

  return (
    <Box style={{ height: "100%", width: "100%" }}>
      <img
        src="https://continuo.com.do/logo192.png"
        style={{
          width: "150px",
          height: "150px",
          margin: "50px auto",
          display: "block",
        }}
        alt="Continuo"
      />

      <Box style={{ width: "500px", margin: "auto" }}>
        <Stack style={{ borderRadius: "10px", flexDirection: "column" }}>
          <legend>
            <strong>Introduzca la nueva contraseña</strong>
          </legend>

          <Box style={{ display: "flex", margin: "5px" }}>
            <TextField
              label="Nueva contraseña"
              type="password"
              name="newPassword"
              style={{ flex: 3 }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>

          <Box style={{ display: "flex", margin: "5px" }}>
            <TextField
              label="Confirme la contraseña"
              type="password"
              name="confirmation"
              style={{ flex: 3 }}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
            />
          </Box>

          <Box style={{ margin: "5px" }}>
            <Button
              onClick={handleCambiarContrasena}
              style={{
                width: "200px",
                height: "40px",
                margin: "auto",
                padding: "0.3em 1.2em",
                borderRadius: "2em",
                boxSizing: "border-box",
                textDecoration: "none",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "300",
                color: "#ffffff",
                backgroundColor: "#4eb5f1",
                textAlign: "center",
                transition: "all 0.2s",
                cursor: "pointer",
                display: "block",
              }}
            >
              Cambiar Contraseña
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
