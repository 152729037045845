import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

/**
 * presenta identificación de la compañía
 * @param RutaLogoCompania logo a presentar, si esta vacio presenta nombre de compañía
 * @param RutaIconoCompania icono a presentar, si esta vacio presenta nombre de compañía
 * @param nombreCompania nombre de la compañía a presentar en caso de no pasar logo o icono
 */
export default function Logo({
  RutaLogoCompania,
  RutaIconoCompania,
  nombreCompania,
}: {
  RutaLogoCompania: string;
  RutaIconoCompania: string;
  nombreCompania: string;
}) {
  return (
    <>
      {RutaLogoCompania ? (
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <img
            style={{ maxHeight: "5rem", verticalAlign: "baseline" }}
            src={"data:image/png;base64," + RutaLogoCompania}
            alt="Logo Compañía"
          />
        </Box>
      ) : (
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography
            component="h2"
            variant="h4"
            style={{ color: "black", lineHeight: "5rem", whiteSpace: "nowrap" }}
          >
            <strong>{nombreCompania}</strong>
          </Typography>
        </Box>
      )}

      {RutaIconoCompania ? (
        <Box sx={{ display: { sm: "none", xs: "block" } }}>
          <img
            style={{ maxHeight: "5rem", verticalAlign: "baseline" }}
            src={"data:image/png;base64," + RutaIconoCompania}
            alt="Icono Compañía"
          />
        </Box>
      ) : (
        <Box sx={{ display: { sm: "none", xs: "block" } }}>
          <Typography
            component="h2"
            variant="h5"
            style={{
              color: "black",
              lineHeight: "5.5rem",
              whiteSpace: "nowrap",
            }}
          >
            <strong>{nombreCompania}</strong>
          </Typography>
        </Box>
      )}
    </>
  );
}
