import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./Styles";
import { NavLink } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { KeyboardArrowDown } from "@mui/icons-material";
import { IModulo } from "../../../AppInterfaces";
import { Consultar } from "../../common/server/funcionesServidor";
import { useContext } from "react";
import { authContext } from "../../MainComponent";
import { fondo2 } from "../../../imagenes";

export default function NavLeft({
  handleResize,
}: {
  handleResize: () => void;
}) {
  const classes = useStyles();

  const {
    companiaSeleccionada: { modulos: modulosDeCompania, r, g, b, a },
    accesos: accesosDelUsuario,
    roleNombre,
    realm,
  } = useContext(authContext);

  const [modulos, setModulos] = useState<IModulo[]>([]);

  const consultar = useCallback(async () => {
    setModulos(
      await Consultar(`api/Modulos/consultar`, undefined, undefined, {
        order: "orden ASC",
      })
    );
  }, []);

  useEffect(() => {
    consultar();
  }, [consultar]);

  const accesosMenu = () => {
    return modulos
      ? modulos
          .filter((modulo) =>
            accesosDelUsuario.some((acceso) => acceso.moduloId === modulo.id)
          )
          .map((modulo, index) =>
            modulosDeCompania.some(
              (moduloC) => moduloC.moduloId === modulo.id
            ) ? (
              <React.Fragment key={modulo.id}>
                <NavLink
                  className={classes.NavLink}
                  style={{
                    left: -100,
                    position: "relative",
                  }}
                  to="#"
                  id={modulo.nombre.replace(/\s/g, "")}
                >
                  <ListItem
                    classes={{
                      root: `${classes.ListItem} ${classes.BorderMain}`,
                    }}
                    style={{
                      animation: `slide-right 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550) ${
                        index * 0.1
                      }s both`,
                      WebkitAnimation: `slide-right 0.5s cubic-bezier(0.680, -0.550, 0.265, 1.550) ${
                        index * 0.1
                      }s both`,
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.ListIcon }}>
                      <span className={modulo.icono} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.ListText }}
                      primary={modulo.nombre}
                    />
                    <ListItemIcon classes={{ root: classes.ListIcon }}>
                      <KeyboardArrowDown />
                    </ListItemIcon>
                  </ListItem>
                </NavLink>

                <UncontrolledCollapse
                  toggler={modulo.nombre.replace(/\s/g, "")}
                >
                  {modulo.accesos.map((acceso) =>
                    accesosDelUsuario.some(
                      (accesoU) => accesoU.accesoId === acceso.id
                    ) ? (
                      <NavLink
                        to={acceso.link ?? ""}
                        className={classes.NavLink}
                        key={acceso.id}
                        onClick={handleResize}
                      >
                        <ListItem
                          button
                          classes={{
                            root: `${classes.ListItem} ${classes.SubmenuLink} ${
                              acceso.nivel === 1 ? classes.nivel1 : ""
                            }`,
                          }}
                        >
                          <ListItemIcon classes={{ root: classes.ListIcon }}>
                            <span className={acceso.icono} />
                          </ListItemIcon>

                          <ListItemText
                            classes={{
                              primary:
                                acceso.nivel === 0
                                  ? classes.ListText
                                  : acceso.nivel === 1
                                  ? classes.ListTextNivel1
                                  : "",
                            }}
                            primary={acceso.nombre}
                          />
                        </ListItem>
                      </NavLink>
                    ) : null
                  )}
                </UncontrolledCollapse>
              </React.Fragment>
            ) : null
          )
      : null;
  };

  return (
    <div
      className={classes.Contenedor}
      style={{
        backgroundColor: `rgba(${r},${g},${b},${a})`,
        backgroundImage: `url("${fondo2}")`,
        overflowY: "auto",
      }}
    >
      <List classes={{ root: classes.List }}>
        <NavLink
          to="DashBoard"
          className={classes.NavLink}
          onClick={handleResize}
        >
          <ListItem button classes={{ root: classes.ListItem }}>
            <ListItemIcon classes={{ root: classes.ListIcon }}>
              <span className="fas fa-chart-pie" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.ListText }}
              primary="DashBoard"
            />
          </ListItem>
        </NavLink>

        {roleNombre === "sysadmin" ? (
          <>
            <NavLink
              to="Configuraciones"
              id="configuraciones"
              className={classes.NavLink}
              onClick={handleResize}
            >
              <ListItem
                button
                classes={{ root: `${classes.ListItem} ${classes.BorderMain}` }}
              >
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <span className="fas fa-wrench" />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.ListText }}
                  primary="Configuraciones"
                />
                <ListItemIcon classes={{ root: classes.ListIcon }}>
                  <KeyboardArrowDown />
                </ListItemIcon>
              </ListItem>
            </NavLink>

            <UncontrolledCollapse toggler="configuraciones">
              <NavLink
                to="Usuarios"
                className={classes.NavLink}
                onClick={handleResize}
              >
                <ListItem
                  button
                  classes={{
                    root: `${classes.ListItem} ${classes.SubmenuLink}`,
                  }}
                >
                  <ListItemIcon classes={{ root: classes.ListIcon }}>
                    <span className="fas fa-users-cog" />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.ListText }}
                    primary="Usuarios y Accesos"
                  />
                </ListItem>
              </NavLink>

              <NavLink
                to="Compania"
                className={classes.NavLink}
                onClick={handleResize}
              >
                <ListItem
                  button
                  classes={{
                    root: `${classes.ListItem} ${classes.SubmenuLink}`,
                  }}
                >
                  <ListItemIcon classes={{ root: classes.ListIcon }}>
                    <span className="fas fa-home" />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.ListText }}
                    primary="Compañías"
                  />
                </ListItem>
              </NavLink>

              <NavLink
                to="Importaciones"
                className={classes.NavLink}
                onClick={handleResize}
              >
                <ListItem
                  button
                  classes={{
                    root: `${classes.ListItem} ${classes.SubmenuLink}`,
                  }}
                >
                  <ListItemIcon classes={{ root: classes.ListIcon }}>
                    <span className="fas fa-file-import" />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.ListText }}
                    primary="Importaciones"
                  />
                </ListItem>
              </NavLink>

              <NavLink
                to="Eventos"
                className={classes.NavLink}
                onClick={handleResize}
              >
                <ListItem
                  button
                  classes={{
                    root: `${classes.ListItem} ${classes.SubmenuLink}`,
                  }}
                >
                  <ListItemIcon classes={{ root: classes.ListIcon }}>
                    <span className="fas fa-list-alt" />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.ListText }}
                    primary="Eventos"
                  />
                </ListItem>
              </NavLink>

              {realm === "1" && (
                <>
                  <NavLink
                    to="Companias"
                    className={classes.NavLink}
                    onClick={handleResize}
                  >
                    <ListItem
                      button
                      classes={{
                        root: `${classes.ListItem} ${classes.SubmenuLink}`,
                      }}
                    >
                      <ListItemIcon classes={{ root: classes.ListIcon }}>
                        <span className="fas fa-home" />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.ListText }}
                        primary="Compañias"
                      />
                    </ListItem>
                  </NavLink>
                </>
              )}
            </UncontrolledCollapse>
          </>
        ) : null}

        {accesosMenu()}
      </List>
    </div>
  );
}
