import React, { forwardRef } from "react";
import {
  OutlinedInputProps,
  SxProps,
  TextField as TextField1,
} from "@mui/material";
import {
  convertirTextoAFecha,
  FormatearFecha,
  parseInputTarget,
} from "../funciones/funciones";

type TextFieldProps = {
  id?: string;
  name: string;
  label?: string;
  onChange?:
    | ((
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        parsedValue: unknown
      ) => void)
    | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  modificando?: boolean;
  value?: number | string;
  type?:
    | "text"
    | "number"
    | "date"
    | "time"
    | "month"
    | "color"
    | "checkbox"
    | "week"
    | "currency"
    | "email"
    | "password";
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  error?: string;
  helperText?: string;
  rows?: number;
  InputProps?: Partial<OutlinedInputProps>;
  disabled?: boolean;
  params?: any;
  justifyContent?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  visible?: boolean;
  maxLength?: number;
  forceUpperCase?: boolean;
  style?: React.CSSProperties;
  autoComplete?: string;
  decimals?: number;
  sx?: SxProps;
};

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      id,
      name,
      label,
      onChange,
      onKeyDown,
      modificando = true,
      value,
      type = "text",
      placeholder = "",
      required = false,
      autoFocus = false,
      error,
      helperText,
      rows,
      InputProps,
      disabled = false,
      params,
      justifyContent = "left",
      visible = true,
      maxLength,
      forceUpperCase = false,
      style,
      autoComplete = "on",
      decimals = 2,
      sx,
    },
    ref
  ) => {
    let nvalue = value;

    const placeholdervalue = placeholder
      ? placeholder
      : type === "number"
      ? "0"
      : type === "currency"
      ? "0.".padEnd(decimals, "0")
      : "";

    if (typeof nvalue === "string") {
      if (forceUpperCase) {
        nvalue = nvalue.toUpperCase();
      }

      if (type === "date") {
        nvalue = FormatearFecha(convertirTextoAFecha(nvalue), "YYYY-MM-DD");
      }
    }

    return visible ? (
      modificando ? (
        <TextField1
          inputRef={ref}
          id={id}
          InputProps={InputProps}
          {...params}
          fullWidth
          disabled={disabled}
          autoFocus={autoFocus}
          required={required}
          name={name}
          label={label}
          placeholder={placeholdervalue}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          margin="normal"
          onChange={(e) => {
            if (onChange && (!maxLength || e.target.value.length <= maxLength))
              onChange(e, parseInputTarget(e.target));
          }}
          onKeyDown={onKeyDown}
          error={error ? true : false}
          helperText={helperText || error}
          value={nvalue === undefined ? nvalue : nvalue || ""}
          type={type === "currency" ? "number" : type}
          multiline={rows ? true : false}
          rows={rows}
          style={style}
          autoComplete={autoComplete}
          sx={{
            ...sx,
            "& input.Mui-disabled, & label.Mui-disabled": {
              "-webkit-text-fill-color": disabled ? "grey" : undefined,
            },
          }}
        />
      ) : (
        <div>
          <div
            style={{
              textAlign: type === "currency" ? "right" : justifyContent,
            }}
          >
            <strong>{label}</strong>
          </div>
          <div
            style={{
              textAlign: type === "currency" ? "right" : justifyContent,
            }}
          >
            {type === "currency"
              ? (+(value || 0)).toLocaleString(undefined, {
                  minimumFractionDigits: decimals,
                  maximumFractionDigits: decimals,
                })
              : nvalue || ""}
          </div>
        </div>
      )
    ) : null;
  }
);
