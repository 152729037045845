import React, { useState, useEffect, useRef } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import { Help } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import Tour from "reactour";
import { Consultar } from "../../../../common/server/funcionesServidor";
import BarraHerramienta from "../../../../common/barraHerramienta/barraHerramienta";
import DetalleGrid from "../../../../common/DetalleGrid/DetalleGrid";
import { AsignarNombreMaterialTable } from "../../../../common/funciones/funciones";
import tourPasos from "./Tour";
import {
  IOrdenServicioOrden,
  IOrdenServicioTecnicoLogin,
} from "../../../../../AppInterfaces";
import { useQuery } from "react-query";
import { queryClient } from "../../../../..";
import Editor from "./editor/Editor";

export default function OrdenServicioTecnicos({
  loginState,
}: {
  loginState: IOrdenServicioTecnicoLogin;
}) {
  const collection = "OrdenServicioOrdenes";
  const titulo = "Ordenes de Servicios";
  const [isTourAbierto, setIsTourAbierto] = useState(false);
  const [documentoEditar, setDocumentoEditar] = useState<IOrdenServicioOrden>();

  const { data, isLoading } = useQuery(
    [collection],
    async () =>
      await Consultar<IOrdenServicioOrden>(
        `api/${collection}/consultarToken`,
        undefined,
        undefined,
        {
          where: `companiaId = ${loginState.companiaId}`,
          order: "codigo desc",
          token: loginState.token,
        }
      )
  );

  useEffect(() => AsignarNombreMaterialTable());

  /**Impresión**/
  const printRef = useRef(null);
  const onImprimir = useReactToPrint({
    content: () => printRef.current,
    pageStyle: "@page { size: letter portrait; margin: 0.4in; }",
  });
  /**Impresión**/

  const toogleTour = () => {
    setIsTourAbierto((isTourAbierto) => !isTourAbierto);
  };

  //Funciones para el editor
  const onVisualizar = (registro: IOrdenServicioOrden) =>
    setDocumentoEditar(registro);
  const onCerrarEditor = () => {
    setDocumentoEditar(undefined);
    queryClient.invalidateQueries(collection);
  };

  //Funciones para el editor

  return documentoEditar ? (
    <Editor
      documentoEditar={documentoEditar}
      funcionCerrar={onCerrarEditor}
      initModificandoAgregando={documentoEditar.id ? false : true}
      loginState={loginState}
    />
  ) : (
    <>
      <Tooltip style={{ zIndex: 2000 }} title="Tour" aria-label="Tour">
        <Help
          className="botonTour"
          onClick={toogleTour}
          fontSize="large"
          id="botonTour"
        />
      </Tooltip>

      <Tour
        steps={tourPasos}
        isOpen={isTourAbierto}
        onRequestClose={toogleTour}
      />

      <BarraHerramienta accionImprimir={onImprimir} />

      <Grid
        container
        item
        ref={printRef}
        className="imprimirListado imprimirSinPrimeraColumna imprimirSinSegundaColumna"
      >
        <DetalleGrid
          isLoading={isLoading}
          data={
            data?.filter(
              (e) =>
                (e.estadoNumEvento === 3 || e.estadoNumEvento === 2) &&
                e.tecnicos.some((a) => a.tecnicoId === loginState.id)
            ) ?? []
          }
          onVisualizar={onVisualizar}
          collection={collection}
          titulo={titulo}
        />
      </Grid>
    </>
  );
}
